import styled from "styled-components";
import { Image } from "react-bootstrap";
import Slider from "react-slick";
import ImageViewer from "react-simple-image-viewer";

import { useCallback, useState } from "react";

interface Props {
  images: any[];
}

const SliderWrapper = styled.div`
  width: 156px;
`;

const ImageWrapper = styled(Image)`
  width: 156px;
  height: 112px;
  object-fit: cover;
  cursor: pointer;
`;

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export function ImageSlider(props: Props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <SliderWrapper>
        <Slider {...sliderSettings}>
          {props.images.map((src, index) => (
            <ImageWrapper key={index} src={src} onClick={() => openImageViewer(index)}  />
          ))}
        </Slider>
      </SliderWrapper>
      {isViewerOpen && (
        <ImageViewer
          src={props.images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
            zIndex: 9999,
          }}
          closeOnClickOutside={true}
        />
      )}
    </>
  );
}
