import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  MapEvent,
} from "@vis.gl/react-google-maps";
import { useApi } from "../../../api/useApi";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import Table from "react-bootstrap/Table";
import { FavoriteIcon } from "../../components/listing/FavoriteIcon";
import { styled } from "styled-components";
import { MapMarker, MapMarkerType } from "../../components/layout/MapMarker";
import { InfoTooltip } from "../../components/InfoTooltip";
import { CreamTypes } from "../../interfaces";
import { ListingExpanded } from "./components";
import { SortableTitle } from "./components/SortableTitle";
import { PercentDecimalDouble, USDollar } from "../../utils";
import { LoadingIndicator } from "../../components";
import { Col, Container, Row } from "react-bootstrap";
import MapHandler from "./components/MapHandler";
import { useState } from "react";
import { MapAutoComplete } from "./components/MapAutoComplete";
import { ModalWindow } from "../../components/ModalWindow";
import { Helmet } from "react-helmet-async";

const styles = [
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const TableWrapper = styled.div`
  height: calc(97vh - 374px);
  overflow-y: scroll;
`;

const StyledTable = styled(Table)`
  tr,
  td {
    border-color: #ebebfb !important;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
  }

  & tr.active td {
    background: rgba(235, 235, 251, 0.5);
    border: none;
    font-weight: bold !important;
  }
  & tr:hover td {
    background: rgba(235, 235, 251, 0.5) !important;
  }
`;

const TdText = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 200px;
`;

interface Props {
  isAdmin: boolean;
  isLoading: boolean;
  minZoomRequired: boolean;
  bbox: CreamTypes.BBox;
  selectedSorting: string;
  listings: ListingCompactView[];
  setListings: (listings: ListingCompactView[]) => void;
  activeListing?: Listing;
  loadListing: (listingKey: string) => Promise<void>;
  onMapChange: (map: CreamTypes.BBox) => void;
  onSortingChange: (sortingKey: string) => void;
  creamUsageAccepted: boolean;
  setCreamUsageAccepted: () => void;
}

export const CreamMain = (props: Props) => {
  const apiContext = useApi();
  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const onChange = (bounds: google.maps.LatLngBounds, zoomLevel?: number) => {
    const { south, north, east, west } = bounds.toJSON();
    props.onMapChange({
      min_lon: west,
      min_lat: south,
      max_lon: east,
      max_lat: north,
      zoom_level: zoomLevel ?? 11,
    });
  };

  const onDragend = (event: MapEvent) => {
    const bounds = event.map.getBounds();
    if (!bounds) return;
    onChange(bounds, event.map.getZoom());
  };

  const onZoomChanged = (event: MapEvent, zoom_level: number) => {
    const bounds = event.map.getBounds();
    if ((!bounds) || (event.map.getZoom() === zoom_level)) {
      return;
    };
    onChange(bounds, event.map.getZoom());
  };

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.addToFavorites(listingKey);
    props.setListings(
      props.listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = true;
        }
        return l;
      })
    );
  };


  const disableListing = async (listingKey: string): Promise<void> => {
    await apiContext?.disableListing(listingKey);
    props.setListings(
      props.listings.filter((l) => {
        if (l.listing_key === listingKey) {
          return false;
        }
        return true;
      })
    );
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.removeFromFavorites(listingKey);
    props.setListings(
      props.listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = false;
        }
        return l;
      })
    );
  };

  return (
    <>
      <Helmet>
        <title>CREAM Search</title>
        <meta name="description" content={`Filter listings by expected rent and return.`} />
        <meta property="og:title" content={`CREAM Search`} />
        <meta property="og:description" content={`Filter listings by expected rent and return.`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>
      <APIProvider apiKey={"AIzaSyCEipg_DVdLYfRSYvmRCuOnpIdnYzTilp0"}>
        <Map
          // onBoundsChanged={onChange}
          onDragend={onDragend}
          onZoomChanged={(event) => onZoomChanged(event, props.bbox.zoom_level)}
          style={{
            height: 300,
          }}
          mapTypeId={"terrain"}
          mapTypeControl={false}
          styles={styles}
          // defaultBounds={{
          //   south: props.bbox.min_lat,
          //   west: props.bbox.min_lon,
          //   north: props.bbox.max_lat,
          //   east: props.bbox.max_lon,
          // }}
          defaultCenter={{
            lat: (props.bbox.max_lat + props.bbox.min_lat) / 2,
            lng: (props.bbox.max_lon + props.bbox.min_lon) / 2,
          }}
          defaultZoom={props.bbox.zoom_level}
          streetViewControl={false}
          maxZoom={16}
          minZoom={6}
        >
          {props.listings.map((listing) => (
            <MapMarker
              type={MapMarkerType.Search}
              selected={
                props.activeListing?.listing_key === listing.listing_key
              }
              onClick={() => props.loadListing(listing.listing_key)}
              key={listing.listing_key}
              lat={listing.latitude}
              lng={listing.longitude}
              markerKey={listing.listing_key}
            />
          ))}
        </Map>
        <MapControl position={ControlPosition.LEFT_TOP}>
          <MapAutoComplete onPlaceSelect={setSelectedPlace} />
        </MapControl>
        <MapHandler place={selectedPlace} />
      </APIProvider>
      {!props.creamUsageAccepted && (
        <ModalWindow title="Find profitable properties by searching our opinion of rent and return
        on all available listings." content1="This is not investment advice. Opinions of potential rent and return are
        based on information available to us at the time of use. Actual rents
        and returns could be significantly different than the opinions
        displayed. No warranty expressed or implied. Use at your own risk." content2="" onAccept={() => props.setCreamUsageAccepted()} />
      )}
      <TableWrapper>
        {props.isLoading && (
          <div className="centeredContent"><LoadingIndicator /></div>
        )}
        {props.minZoomRequired && (
          <div className="centeredContent">Please zoom in to see more results</div>
        )}
        {props.listings.length > 0 && props.creamUsageAccepted && (
          <>
            <StyledTable>
              <thead>
                <tr className='sticky'>
                  <th>Address</th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='beds'
                      label='Beds'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='year'
                      label='YrBlt'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='price'
                      label='Price'
                    />
                  </th>
                  <th>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='rent'
                      label='Rent'
                    />
                  </th>
                  <th colSpan={2}>
                    <SortableTitle
                      disabled={apiContext?.isLoading}
                      setSorting={props.onSortingChange}
                      selectedSort={props.selectedSorting}
                      value='return'
                      label='Return'
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.listings.map((listing) => (
                  <>
                    <tr
                      key={listing.listing_key}
                      onClick={() => props.loadListing(listing.listing_key)}
                      className={
                        listing.listing_key === props.activeListing?.listing_key
                          ? "active"
                          : ""
                      }
                    >
                      <TdText>
                        <InfoTooltip
                          label={listing.full_address}
                          placement='top'
                        >
                          {listing.full_address}
                        </InfoTooltip>
                      </TdText>

                      <td>{listing.bedrooms_total}</td>
                      <td>{listing.year_built}</td>
                      <td>{USDollar.format(listing.list_price)}</td>
                      <td>
                        {listing.estimation?.rent_prediction
                          ? USDollar.format(listing.estimation?.rent_prediction)
                          : null}
                      </td>
                      <td>
                        {listing.estimation?.return_prediction
                          ? PercentDecimalDouble.format(
                            listing.estimation.return_prediction
                          ) + "%"
                          : null}
                      </td>
                      <td>
                        {/* <FavoriteIcon
                          favorite={listing.favorite}
                          loading={false}
                          onClick={() =>
                            listing.favorite
                              ? handleRemoveFromFavorites(listing.listing_key)
                              : handleAddToFavorites(listing.listing_key)
                          }
                        /> */}
                      </td>
                    </tr>
                    {listing.listing_key ===
                      props.activeListing?.listing_key && (
                        <ListingExpanded
                          isAdmin={props.isAdmin}
                          onListingDisable={disableListing}
                          listing={props.activeListing}
                        />
                      )}
                  </>
                ))}
              </tbody>
            </StyledTable>
          </>
        )}
      </TableWrapper>
    </>
  );
};
