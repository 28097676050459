import { useState } from "react";

export const getStorageKey = (keyName: string): Promise<string | null> => new Promise((resolve, reject) => {
  const item = localStorage.getItem(keyName)
  resolve(item)
})

export const setStorageKey = (key: string, value: any): Promise<string | null> => new Promise((resolve, reject) => {
  if (value === null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, value)
  }
  resolve(null)
})

export const useStorage = (keyName: any, defaultValue: any): [any, (newValue: any) => Promise<any>] => {
  const [storedValue, setStoredValue] = useState<null | string>(defaultValue);

  const setValue = async (newValue: any): Promise<void> => {
    await setStorageKey(keyName, newValue)
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};





