import Accordion from "react-bootstrap/Accordion";
import { UserAccount } from "./UserAccount";
import { PlanManagement } from "../dashboard";
import { Helmet } from "react-helmet-async";

export const Profile = () => {
  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta property="og:title" content={`Profile`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>


      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen flush className='mt-2'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>ACCOUNT</Accordion.Header>
          <Accordion.Body>
            <div style={{ width: "50%" }}>
              <UserAccount />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey='1'>
        <Accordion.Header>BILLING</Accordion.Header>
        <Accordion.Body>
          <div style={{ width: "70%" }}>
            <PlanManagement />
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
      </Accordion>
    </>
  );
};
