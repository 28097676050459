import React, { useEffect, useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { Module } from "../interfaces/module";
import { useApi } from "../../api/useApi";
import { useAuth } from "./useAuth";

const defaultModules: Module[] = [
  {
    added: false,
    moduleId: "rentomatic",
    title: "RENT-O-MATIC: Rent estimates",
    description:
      "Estimate potential rent on every active listing.  See nearby and recent comparables used to derive the estimate.",
    price: "Added",
    imagePath: "/images/module_icons/rent-o-matic.png",
    url: "/rentomatic",
  },
  {
    added: false,
    moduleId: "proformanator",
    title: "PROFORMANATOR: Proforma generator",
    description:
      "Eliminate the tedium of analyzing listings for investability. Auto-filled analysis for one-click profit & loss on every listing.",
    price: "$9.95/mo",
    imagePath: "/images/module_icons/proformanator-logo.png",
    url: "/proforma",
  },
  {
    added: false,
    moduleId: "cream",
    title: "C.R.E.A.M: Filter listings by potential return",
    description:
      "Quickly locate potentially profitable properties by searching our opinion of rent and return on all available listings.",
    price: "$34.95/mo",
    imagePath: "/images/module_icons/cream_logo.png",
    url: "/cream",
  },
];

const freeModules: Module[] = [
  {
    added: true,
    moduleId: "dossier",
    title:
      "DOSSIER: Obtain listing disclosures, attachments, history and reports",
    description:
      "Deep dive any listing, verify owner information, non-public documents, complete listing history, and more.",
    price: "1 credit",
    imagePath: "/images/module_icons/dossier.png",
    url: "/dossier",
  },
  {
    added: true,
    moduleId: "agency",
    title: "Agency Services",
    description:
      "On-demand, fee-based services from real estate professionals.",
    price: "PRICE",
    imagePath: "/images/module_icons/agency-services.png",
    url: "/agency",
  },
];

type ContextType = {
  modules: Module[];
  loading: boolean;
  setModules: (modules: Module[]) => void;
  hasRentomatic: () => boolean;
  hasProformanator: () => boolean;
  hasDossier: () => boolean;
  hasNoModules: () => boolean;
  getUserModules: () => void;
};

const ModulesContext = createContext<ContextType | null>(null);

export const ModulesProvider = ({ children }: any) => {
  const authContext = useAuth();
  const context = useApi();
  const [modules, setModules] = useState<Module[]>([
    ...defaultModules,
    ...freeModules,
  ]);
  const [loading, setLoading] = useState(true);

  const hasProformanator = () => {
    return modules.some((m) => m.moduleId === "proformanator" && m.added);
  };
  const hasDossier = () => {
    return modules.some((m) => m.moduleId === "dossier" && m.added);
  };
  const hasRentomatic = () => {
    return modules.some((m) => m.moduleId === "rentomatic" && m.added);
  };
  const hasNoModules = () => {
    return (
      modules.filter((x) => x.added).length === defaultModules.length &&
      loading === false
    );
  };

  const getUserModules = () => {
    context?.getUserModules().then((userModules) => {
      setModules([
        ...defaultModules.map((x) => {
          x.added =
            userModules.filter((m: any) => m.module_id === x.moduleId).length >
            0;
          return x;
        }),
        ...freeModules,
      ]);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (authContext?.userToken) {
      getUserModules();
    }
  }, [authContext?.userToken]);

  const value = useMemo(
    () => ({
      loading,
      modules,
      setModules,
      hasProformanator,
      hasRentomatic,
      hasDossier,
      hasNoModules,
      getUserModules,
    }),
    [authContext?.userToken, modules, loading]
  );

  return (
    <ModulesContext.Provider value={value}>{children}</ModulesContext.Provider>
  );
};

export const useModules = () => {
  return useContext(ModulesContext);
};
