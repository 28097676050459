import { Button, Form, Table } from "react-bootstrap";
import { Bundle } from "../../../api/interfaces/modules";
import { useEffect, useState } from "react";
import { useApi } from "../../../api/useApi";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

interface Props {
  bundles: Bundle[];
  selectedBundle: Bundle | null;
  activeBundle: Bundle | null;
  onSelect: (bundle: Bundle, selected: boolean) => void;
  addCredits: () => void;
}

const AddCreditButton = styled.button`

color: #FFF;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 15px */
background: #3EAF3F;
border: none;
width: 39px;
height: 23.301px;
flex-shrink: 0;
border-radius: 4px;
`

export function Checkout({
  bundles,
  selectedBundle,
  activeBundle,
  onSelect,
  addCredits,
}: Props) {
  const apiContext = useApi();
  const [credits, setCredits] = useState<number>(0);
  useEffect(() => {
    apiContext?.getUserData().then((user) => setCredits(user.credits));
  }, [activeBundle]);
  return (
    <>
      <Helmet>
        <title>Billing</title>
        <meta property="og:title" content={`Billing`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>
      <Table className='checkoutTable'>
        <thead>
          <tr>
            <th>Services</th>
            <th> </th>
            <th colSpan={2}>Select</th>
          </tr>
        </thead>
        <tbody>
          {bundles.map((bundle) => {
            return (
              <tr key={bundle.id}>
                <td className={`checkoutProductWeb`} width='60%'>

                  {bundle.name}
                  <span>{bundle.description}</span>
                </td>
                <td align='center' className={`checkoutPriceWeb`} width='20%'>
                  {bundle.pricing}
                  <span>per month</span>
                </td>
                <td>
                  <Form.Check
                    type='switch'
                    checked={
                      bundle.id === selectedBundle?.id ||
                      (bundle.active && selectedBundle === null)
                    }
                    onChange={(e) => onSelect(bundle, e.target.checked)}
                  />
                </td>
                <td>
                  {activeBundle && activeBundle.id === bundle.id && (
                    <span className='activePlan'>Active</span>
                  )}
                </td>
              </tr>
            );
          })}
          <tr>
            <td className={`checkoutProductWeb`} width='60%'>
              50 Credits<span>apply credits towards report usage</span>
            </td>
            <td align='center' className={`checkoutPriceWeb`} width='20%'>
              $42.95
            </td>
            <td colSpan={1}>
              <AddCreditButton onClick={() => addCredits()} className="plausible-event-name=Button+Add+Credits">Add</AddCreditButton>

            </td>
            <td>
              <span className='checkoutPrice'>{credits} remain</span>
            </td>
          </tr>
        </tbody>
      </Table>
      {/* <PageTitle>
        CREDIT BALANCE<span className='fright'>{credits} credits</span>
      </PageTitle>
      <Table className='checkoutTable'>
        <tbody>
          <tr>
            <td className='checkoutProduct' width='60%'>
              Dossier Reports
            </td>
            <td align='center' className='checkoutPrice' width='20%'>
              20 credits
              <span>per peport</span>
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <td className='checkoutProduct' width='60%'>
              Look Ups
            </td>
            <td align='center' className='checkoutPrice' width='20%'>
              1 credit
              <span>per lookup</span>
            </td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </Table> */}
    </>
  );
}
