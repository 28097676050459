import styled from "styled-components";
import { Image, ProgressBar } from "react-bootstrap";

import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApi } from "../../api/useApi";
import { SearchTypeAhead } from "./SearchTypeAhead";
import { ErrorBox } from "./not_found";
import { Helmet } from "react-helmet-async";


const Recent = styled.div`
  width: 400px;
  font-size: 16px;
  text-align: center;
  position: relative;
  padding-top: 100px;

  button {
    border: none;
    background: none;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const Logo = styled.div`
  width: 200px;
  margin: 0px 0px;
  img {
    width: 100%;
  }
`;

const DivCenter = styled.div`
  width: 60%;
`;

export const SearchPage = () => {
  const navigate = useNavigate();
  const apiContext = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0)
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [options, setOptions] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const viewProperty = (propertyId: string, address: string) => {
    navigate(`/rentomatic/${propertyId}`);
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setProgress(progress + 1)
      }, 100)
    } else {
      setProgress(0)
    }
  }, [isLoading, progress])

  const getUserHistory = async () => {
    const results = await apiContext?.getSearchHistory();
    results.reverse().length = Math.min(results.length, 10);
    setOptions(results);
  };

  useEffect(() => {
    getUserHistory()
  }, [])

  return (
    <Wrapper className='mt-3'>
      <Helmet>
        <title>Self Realty - Search</title>
        <meta name="description" content={`Enter an address or paste a listing url.`} />
        <meta property="og:title" content={`Self Realty - Search`} />
        <meta property="og:description" content={`Enter an address or paste a listing url.`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>
      <Logo className='mb-3'>
        <Image src='/images/horizontal-logo.png' />
      </Logo>
      <SearchTypeAhead
        setIsLoading={setIsLoading}
        setStatusMessage={setStatusMessage}
        onChange={viewProperty}
        isLoading={isLoading}
        value={searchParams.get("input") ?? undefined}
      />
      {isLoading && (
        <DivCenter className='mt-5'>
          <ProgressBar animated now={progress} />
          <div className='alignCenter mt-2'>{statusMessage}</div>
        </DivCenter>
      )}
      {options.length > 0 && <>
        <Recent className="mt-3">
          <div className="mb-1 mt-3"><b>Recent searches</b></div>
          {options.map(x => <button key={x.propertyId} onClick={() => viewProperty(x.v1PropertyId, x.address)}>{x.address}</button>)}
        </Recent>
      </>}
      <ErrorBox />
    </Wrapper>

  );
};
