import * as Sentry from "@sentry/browser";
import { Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { LoadingButton } from "../../components";
import { useAuth } from "../../hooks/useAuth";

export function FeedbackPage() {
  const authContext = useAuth();
  const [message, setMessage] = useState<string | null>("");
  const [name, setName] = useState<string | undefined>(
    `${authContext?.user?.first_name} ${authContext?.user?.last_name}`
  );
  const [email, setEmail] = useState<string | undefined>(
    authContext?.user?.email
  );

  const sendFeedback = async () => {
    if (message !== "") {
      Sentry.sendFeedback(
        {
          name: name,
          email: email,
          message: `
            ${message}

            at ${window.location.href}
          `,
        },
        {
          includeReplay: true,
        }
      );
    }
    setMessage(null);
  };

  useEffect(() => {
    setName(`${authContext?.user?.first_name} ${authContext?.user?.last_name}`);
    setEmail(authContext?.user?.email)
  }, [authContext?.user?.first_name, authContext?.user?.last_name, authContext?.user?.email]);

  return (
    <div className='d-flex justify-content-center'>
    <Form className='mt-3' style={{ width: "70%" }}>
      {message === null && <>Thank you! Your message has been sent!</>}
      {message !== null && (
        <>
          <Form.Group className='mb-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              type='text'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Email</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type='email'
            />
          </Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Group className='mb-3'>
            <Form.Control
              as='textarea'
              onChange={(e) => setMessage(e.target.value)}
              rows={5}
            />
          </Form.Group>
          <LoadingButton label='Send Message' callback={sendFeedback} />
        </>
      )}
    </Form>
    </div>
  );
}
