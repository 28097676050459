import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { useListing } from "../../hooks/useListing";
import { ContentWithSideBar } from "../../components/layout";
import { Dossier } from "../../../api/interfaces/dossier";
import { DossierView } from "./DossierView";
import { DossierSidebar } from "./DossierSidebar";

export const DossierWrapper = () => {
  const apiContext = useApi();
  const navigate = useNavigate();
  const listingContext = useListing();
  const { listingString } = useParams();
  const [dossier, setDossier] = useState<Dossier | null>(null);
  const [order, setOrder] = useState<Dossier | null>(null);
  const [credits, setCredits] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const listingKey = listingString ?? listingContext?.listingKey;
  const mlsListing =
    listingContext?.listing &&
    listingContext.listing.data_source !== "CORELOGIC" &&
    listingContext.listing.data_source !== "CUSTOM";

  const handleLoadDossierRequest = async (): Promise<void> => {
    try {
      const data = await apiContext?.getDossier(listingKey!);
      setDossier(data);
    } catch (error) {
      setDossier(null);
    }
  };

  const requestDossier = async () => {
    try {
      const data = await apiContext?.requestDossier(listingKey!, window.location.href);
      setDossier(data);
    } catch (error) {
      setDossier(null);
    }
  };

  useEffect(() => {
    apiContext?.getUserData().then((user) => setCredits(user.credits));
  }, [dossier]);

  useEffect(() => {
    if (listingKey) {
      setInitialLoading(true);
      handleLoadDossierRequest()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingKey]);

  if (!listingContext?.listing && !listingString) {
    navigate("/not_found");
    return null;
  }

  if (initialLoading || !listingContext) {
    return <ContentWithSideBar loading  />;
  }

  if (!mlsListing) {
    return (
      <ContentWithSideBar
        center={
          <h2 className='mt-3'>
            This property is not currently supported by Dossier
          </h2>
        }
        sidebar={<></>}
      />
    );
  }

  if (dossier?.delivered && order === null) {
    setOrder(dossier);
  }

  return (
    <ContentWithSideBar
      center={
        <DossierView
          order={order}
          dossier={dossier}
          listing={listingContext.listing}
        />
      }
      sideBarTitle='Dossier'
      sidebar={
        <DossierSidebar
          requestDossier={requestDossier}
          dossier={dossier}
          credits={credits}
          setActiveDossier={setOrder}
        />
      }
    />
  );
};
