import { Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LoadingIndicator } from "../../components";
import { Module } from "../../interfaces/module";
import { useModules } from "../../hooks/useModules";
import { useEffect } from "react";

export function CreditsTopUp() {

  return (
    <>
      Adding credits to your account...
    </>
  );
}
