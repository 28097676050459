import { useNavigate } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { LoadingButton } from "../../components";
import styled from "styled-components";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Dossier } from "../../../api/interfaces/dossier";
import { OrderList } from "./components";
import { PageSubTitle } from "../../components/layout/PageSubtitle";

const DossierFeatures = styled.ul`
  padding: 0;
  margin: 0;
  font-size: 16px;

  li {
    list-style: none;
  }
`;

const ImageCol = styled(Col)`
  flex: 0 0 75px;
  padding: 0;
`;

const CreditsBalance = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const SidebarFooter = styled.div`
  position: absolute;
  bottom: 0px;
  width: calc(100% - 24px)
`;

interface Props {
  dossier: Dossier | null;
  credits: number;
  requestDossier: () => Promise<void>;
  setActiveDossier: (dossier: Dossier) => void;
}

export const DossierSidebar = (props: Props) => {
  const apiContext = useApi();
  const navigate = useNavigate();

  return (
    <>
      <p className='mb-2'>Use DOSSIER for deep reports on any listing.</p>
      <Container>
        <Row>
          <ImageCol>
            <Image width={75} src='/images/module_icons/dossier.png' />
          </ImageCol>
          <Col>
            <DossierFeatures>
              <li>✓ listing disclosures & attachments</li>
              <li>✓ ownership verification</li>
              <li>✓ listing & mortgage history</li>
              <li>✓ homestead status, and more</li>
            </DossierFeatures>
          </Col>
        </Row>
      </Container>

      <div className='mt-3'>
        <br />
        <br />
        {props.dossier === null && props.credits > 0 && (
          <>
            <LoadingButton
              label={<b>Order DOSSIER for 1 credit</b>}
              disabled={apiContext?.isLoading}
              callback={props.requestDossier}
            />
          </>
        )}
        {props.dossier !== null && props.credits > 0 && (
          <>
            <Button variant='primary' disabled>
              Order placed{" "}
              {new Date(props.dossier.created_at).toLocaleDateString("en-US")}
            </Button>
          </>
        )}
        {props.dossier !== null && props.credits === 0 && (
          <>
            <Button
              variant='primary'
              onClick={() => navigate("/dashboard")}
            >
              <b>Get More Credits</b>
            </Button>
          </>
        )}
      </div>
      <p className='alignCenter mt-3'>
        Results will be emailed to you within 24 hours.
      </p>
      <SidebarFooter>
        <PageSubTitle>
          <div>Order History</div>
          <CreditsBalance>{props.credits} credits remaining</CreditsBalance>
        </PageSubTitle>
        <OrderList onSelect={props.setActiveDossier} />
      </SidebarFooter>
    </>
  );
};
