import { useApi } from "../../../api/useApi";
import {
  Estimation,
  Listing,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Rentomatic } from "./Rentomatic";
import { useListing } from "../../hooks/useListing";
import { ContentWithSideBar } from "../../components/layout";
import { RentomaticSidebar } from "./RentomaticSidebar";
import { useNavigate, useParams } from "react-router-dom";
import { useModules } from "../../hooks/useModules";

export const RentomaticWrapper = () => {
  const apiContext = useApi();
  const listingContext = useListing();
  const modulesContext = useModules();
  const navigate = useNavigate();
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [estimation, setEstimation] = useState<Estimation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const { listingString } = useParams();

  const handleLoadListing = async (listingKey: string): Promise<void> => {
    const data = listingKey.includes(':') ? await apiContext?.getPropertyMetadata(listingKey) : await apiContext?.getListing(listingKey);
    if (data) {
      listingContext?.setListing(data);
      listingContext?.setListingKey(data.listing_key);
      handleLoadComparables(data);
    }
  };

  const handleLoadComparables = async (
    listing: Listing,
    comparables_ids: string[] = []
  ): Promise<void> => {
    setLoading(true);
    try {
      const data = await apiContext?.getRentomatic(listing, comparables_ids);
      if (data) {
        setComparables(data.comparables);
        setEstimation(data.estimation);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleComparablesUpdate = async (
    comparablesIds: string[]
  ): Promise<void> => {
    setLoading(true);
    try {
      await handleLoadComparables(listingContext?.listing!, comparablesIds);
    } catch (error) {
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (listingContext?.listing && !listingString) {
      setInitialLoading(true);
      handleLoadComparables(listingContext.listing).finally(() => {
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingContext?.listing]);

  useEffect(() => {
    if (listingString) {
      setInitialLoading(true);
      handleLoadListing(listingString).finally(() => {
        setInitialLoading(false);
      });
    } else {
      setInitialLoading(false);
    }
  }, [listingString]);

  if (initialLoading || modulesContext?.loading) {
    return <ContentWithSideBar loading />;
  }

  if (!modulesContext?.hasRentomatic()) {
    navigate(`/dossier`);
    return null;
  }

  if (!listingContext?.listing && !listingString) {
    navigate("/not_found");
    return null;
  }

  return (
    <ContentWithSideBar
      center={
        <Rentomatic
          loading={loading}
          listing={listingContext?.listing!}
          handleComparablesUpdate={handleComparablesUpdate}
          comparables={comparables}
          estimation={estimation}
        />
      }
      sidebar={
        <RentomaticSidebar
          listing={listingContext?.listing!}
          estimation={estimation}
        />
      }
    />
  );
};
