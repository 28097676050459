import { Carousel } from "react-bootstrap";

export const TipsCarousel = () => {
    return (
        <Carousel interval={10000}>

            <Carousel.Item>
                <p>
                    <b>
                        Tip #1: Type "self.realty/" in front of any listing URL to access Self Realty services while browsing homes online.
                    </b>
                </p>
                <img
                    className="d-block w-100"
                    src="/images/prependlink.jpg"
                    alt="Prepend link"
                />
            </Carousel.Item>

            <Carousel.Item>
                <p>
                    <b>
                        Tip #2: Drag this link - <a href="javascript:(function(){var%20currentUrl=encodeURIComponent(window.location.href);window.open('https://self.realty/'+currentUrl,'_blank');})();">Self.Realty</a> - into your bookmarks bar for one-click access to Self Realty services while browsing homes online.
                    </b>
                </p>
                <img
                    className="d-block w-100"
                    src="/images/bookmarklet1.jpg"
                    alt="Bookmarklet"
                />
            </Carousel.Item>

            <Carousel.Item>
                <p>
                    <b>
                        Tip #3: Paste listing url directly into Self Realty
                    </b>
                </p>
                <img
                    className="d-block w-100"
                    src="/images/paste-listing-link.jpg"
                    alt="Paste url into self realty"
                />
            </Carousel.Item>
        </Carousel>
    );
};
