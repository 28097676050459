import * as Sentry from "@sentry/browser";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Menu from "./Menu";
import { Container } from "react-bootstrap";
import { styled } from "styled-components";
import { Navigation } from "./layout/Navigation";
import { useAuth } from "../hooks/useAuth";
import { ModalWindow } from "./ModalWindow";
import { useModules } from "../hooks/useModules";

const View = styled.div`
  width: 100%;
  background: linear-gradient(146deg, #ffcf6f 73.46%, #fff 113.1%);
  
  padding: 45px 0 22px;
  font-size: 14px;
  color: #000;
  position: relative;
  padding-left: 88px;
  
  z-index: 200;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Sidebar = styled.div`
  z-index: 400;
  position: absolute;
  height: calc(100% - 40px);
  top: 40px;
  left: 0px;
  width: 100px;
  background: linear-gradient(175deg, #ffcf6f 78%, #fff 160.27%);

  display: flex;
  flex-direction: column;
  align-items: center;

  .menuBtn {
    width: 72px;
    height: 72px;
    color: #c8c8ec;
    font-size: 20px;
    background-color: #383d53;
    border: none;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
    border: 2px solid transparent;
    box-sizing: content-box;

    &.moduleActive {
      border: 2px solid #ffb41d;
    }

    img {
      width: 72px;
      height: 72px;
      display: block;
    }
  }
`;


export function LayoutView() {
  const location = useLocation();
  const navigate = useNavigate();
  const modulesContext = useModules();
  const [searchParams] = useSearchParams();
  const state = location.state;
  const auth = useAuth();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  function logCustomError(err: string) {
    const currentUrl = window.location.href;
    const captureContext = {
      tags: {
        url: currentUrl,
      },
    };
    Sentry.captureMessage(`${err} for ${currentUrl}`, captureContext);
  }

  const acceptDisclaimer = () => {
    setShowDisclaimer(false)
    const path = searchParams.get("input") ? `/search?input=${searchParams.get("input")}` : ".";
    navigate(path, { replace: true });
  }

  useEffect(() => {
    if (state?.diclaimer) {
      setShowDisclaimer(true);
    }
  }, [state]);


  useEffect(() => {
    if (modulesContext?.hasNoModules() === true && !location.pathname.startsWith("/dashboard")) {
      navigate("/dashboard/checkout");
    }
  }, [modulesContext?.modules, showDisclaimer, location]);


  return (
    <>
      <View id='view'>
        <Sidebar id='sidebar'>
          <Menu />
        </Sidebar>
        <Container>
          {auth?.userToken && <Navigation />}
          <Outlet
            context={{
              logCustomError,
            }}
          />
          {showDisclaimer && <ModalWindow title="Welcome to Self Realty [beta]! 🚀" content1="This beta software best experienced on your desktop and some features may not work as intended. Please use the feedback link in the bottom left of your screen. Your participation is invaluable in helping us improve." content2="As a token of our appreciation, enjoy 25% off Self Realty Professional using code 'TEXAS25'—valid at checkout through February 14th! 🎉" confirmLabel="I Agree" onAccept={acceptDisclaimer} />}
        </Container>
      </View>
    </>
  );
}
