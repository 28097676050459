import { Image } from "react-bootstrap";
import { styled } from "styled-components";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

export const AgencyPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <title>Agency Services - Coming Soon</title>
        <meta name="description" content={`On-demand, fee-based services from real estate professionals.`} />
        <meta property="og:title" content={`Agency Services`} />
        <meta property="og:description" content={`On-demand, fee-based services from real estate professionals.`} />
        <link rel="image_src" href="/images/self-logo-og.png" />
        <meta property="og:image" content="/images/self-logo-og.png" />
        <meta name="twitter:image:src" content="/images/self-logo-og.png" />
      </Helmet>
      <Image width={300} src='/images/module_icons/agency-services.png' />
      <h1>Agency Services</h1>
      <p>On-demand, fee-based services from real estate professionals.</p>
      <h2>- Coming Soon -</h2>
    </Wrapper>
  )
}